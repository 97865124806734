

.default__button--big {
    z-index:9;
    display: flex;
  background: rgba(0,0,0,0.8) !important;
  border: 0 !important;
  line-height: 1 !important;
  color: #fff !important;
  text-align: center !important;
  outline: 0 !important;
  /* padding: 0 !important; */
  cursor: pointer !important;
  position: absolute !important;
  opacity: 1;
  visibility: visible !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important;
  /* font-size: 64px !important; */
  transition: all .2s !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important;
}

.is-playing {
  opacity: 0 !important;
  visibility: hidden !important;
  -ms-transform: translate(-50%, -50%) scale(1.3) !important;
      transform: translate(-50%, -50%) scale(1.3) !important;
      
}

.is-playing-control {
  /* opacity: 0 !important; */
  display: none !important;
  -ms-transform: translate(-50%, -50%) scale(1.3) !important;
      transform: translate(-50%, -50%) scale(1.3) !important;
}

.is-replay-control {
  z-index:9;
  display: flex;
  background: rgba(0,0,0,0.8) !important;
  border: 0 !important;
  line-height: 1 !important;
  color: #fff !important;
  text-align: center !important;
  outline: 0 !important;
  /* padding: 0 !important; */
  cursor: pointer !important;
  position: absolute !important;
  opacity: 1;
  visibility: visible !important;
  top: 50% !important;
  left: 30% !important;
  -ms-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important;
  /* font-size: 64px !important; */
  transition: all .2s !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important;
}
.is-forwar-control {
  z-index:9;
  display: flex;
  background: rgba(0,0,0,0.8) !important;
  border: 0 !important;
  line-height: 1 !important;
  color: #fff !important;
  text-align: center !important;
  outline: 0 !important;
  /* padding: 0 !important; */
  cursor: pointer !important;
  position: absolute !important;
  opacity: 1;
  visibility: visible !important;
  top: 50% !important;
  left: 70% !important;
  -ms-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important;
  /* font-size: 64px !important; */
  transition: all .2s !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important;
}

.speed-bar {
    z-index:9;
    display: flex;
  /* background: rgba(0,0,0,0.8) !important; */
  border: 0 !important;
  line-height: 1 !important;
  color: #fff !important;
  text-align: center !important;
  outline: 0 !important;
  /* padding: 0 !important; */
  cursor: pointer !important;
  position: absolute !important;
  opacity: 1;
  visibility: visible !important;
  top: 70% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important;
  /* font-size: 64px !important; */
  transition: all .2s !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important;
}

.speed-bar button{
  background: rgba(0,0,0,0.8) !important;
  text-align: center !important;
  /* -ms-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important; */
  margin: 5px;
  /* transition: all .2s !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important; */
    
  }

