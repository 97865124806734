
  $column-width: 480px;
  $gutter-width: 20px;
  
  .app {
    margin: auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-align: center;
  }
  
  .section {
    display: inline-block;
    max-width: $column-width;
    margin: $gutter-width;
    text-align: left;
    vertical-align: top;
  }
  

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .player-wrapper {
    width: 100vw;
    height: 80vh;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
  .player-wrapper {
    width: 95vw;
    height: 80vh;
}}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  .player-wrapper {
    width: 95vw;
    height: 80vh;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .player-wrapper {
    width: 95vw;
    height: 80vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {  
  .player-wrapper {
    width: 95vw;
   height: 80vh
}}
  
  
  .react-player {
    margin-bottom: 10px;
    /* background: rgba(0, 0, 0, .1); */
    background: #000000
  }
  
  .faded {
    color: rgba(0, 0, 0, .5);
  }
  
  .footer {
    margin: $gutter-width;
  }
  
  
  .player-wrapper {
    position: relative;
    /* padding-top: 56.25% Player ratio: 100 / (1280 / 720) */
    /* margin: 1em; */
  }
  .player-wrapper-landscape {
    width: 100vw;
    height: 100vh;
  }
   
  .react-player {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  
  .controls{
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-wrap: wrap;
    background:rgba(0, 0, 0, 0.5);
  
  }

  .marquee-controls{
    display: flex;
    position: absolute;
  
    width: 100%;
    flex-wrap: wrap;
    background:transparent;
  }
  .marquee-controls-down{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    flex-wrap: wrap;
    background:transparent;
     
  }
  /* .speed-bar{
    position: absolute;
    top: 65%;
    z-index:9;
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: center;
  } */

/* 
  .speed-bar button{
    margin: 5px;
    
  } */


  .load-bar{
    height: 5px;
    top: 0;
    left: 0;
    width: 100%;
  }

   .slider {
    width: 100%;
    position: absolute;
    top: 0px;
    background: transparent;
    -webkit-appearance: none;
    width: 100%;
     outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    left: 0px;
    right: 0px;
    margin: 0px;
    cursor: pointer;
    z-index: 100;
  }
  
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 5px;
    background: transparent;
    cursor: pointer;
   
  }
  
  .slider::-moz-range-thumb {
    width: 0;
    height: 5px;
    background:transparent;
    cursor: pointer;
  }


  .load-progress{
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border-radius: 5;
    height: inherit;
    /* cursor: pointer; */
    display: block;
  
  }
  .button-controls{
   display: flex;
   flex-direction: row;
   margin-top: 5px;
   width: 100%;
  }

  .play-button button{ 
     background: none;
     border: 0;
     outline: 0;
     cursor: pointer;
     color: #fff;
  }
  .play-button button::before{ 
    display: inline-block;
    font-size: 28px;
    color: #fff;
    
  }
  
  .volume-button{
    display: flex;
    align-items: center;
    /* width: 100%; */
    flex-wrap: wrap;
   
  }

   .volume-button button{
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #fff;
  }
  .volume-button button::before{ 
    display: inline-block;
    font-size: 28px;
    color: #fff;
    
  }

  .fullscreen-button{
    position: absolute;
    right: 0;
    display: flex;
    bottom: 0;
    color: #fff;
  }
  .fullscreen-button button{
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #fff;
  }
  .duration-info{
    color: #fff;
    align-self: center;
    margin-left: 10px;
  }

  .getTime {
  background: #f36e65;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  width: 150px;
  margin: 0;
  font-family: Arial;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index:9;
}

.slider-volume {
  background:white;
  -webkit-appearance: none;
   outline: none;
  opacity: 0.8;
  -webkit-transition: .2s;
  transition: opacity .2s;
  height: 2px;
}


.slider-volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider-volume::-moz-range-thumb {
  width:10px;
  height: 10px;
  background:white;
  cursor: pointer;
}

.bookmark-bar{
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: inherit;
  /* cursor: pointer; */
  display: block;
  top: 0px;
  position: absolute;
  
  }


  .tooltip {
    top: -1px;
    position: absolute;
    margin: 0px;
    padding: 0px;
    border: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
 }
 .tooltip::before {
    background-color: white;
    border: 1px solid #888;
    border-radius: 2px;
    color: #444;
    content: attr(data-title);
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    padding: 2px 5px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 1;
 }
 .tooltip:hover::before {
    display: block;
 }

 .loader-div{
    position: absolute;
    top:0;
    bottom: 0;
    align-items: center;
    width: 100%;
    display: flex;
       
 }
 /*.react-player::after {
  z-index: 1000;
  content: "";
  width: calc(100% / (16 / 11.5));
  position: absolute;
  top: 0;
  bottom: 0;
  height: 0;
  margin: auto;
  left: 0;
  right: 0;
  padding-bottom: calc(100% / (16 / 9));
  background: url("../../../../assets/img/icons/logo_bg.jpg") no-repeat;
  background-position: top right;
  background-size: 13% 10%;
  margin-top: 0.5%;
  padding-right: 63px;
  margin-left: 10%;
}

@media only screen and (min-width: 600px) and (orientation: landscape) {
  .react-player::after {
    z-index: 1000;
    content: "";
    width: calc(100% / (16 / 11.5));
    position: absolute;
    top: 0;
    bottom: 0;
    height: 0;
    margin: auto;
    left: 0;
    right: 0;
    padding-bottom: calc(100% / (16 / 9));
    background: url("../../../../assets/img/icons/logo_bg.jpg") no-repeat;
    background-position: top right;
    background-size: 17% 13% !important;
    margin-top: 0.5%;
    padding-right: 63px;
    margin-left: 15% !important;
  }
}
*/
@media only screen and (max-width: 600px) {
  .player-wrapper {
    width: 100vw;
    height: 80vh;
  }
  
  /*.react-player::after {
    content: "";
    width: 100% !important;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 0;
    margin: auto;
    left: 0;
    right: 0;
    padding-bottom: calc(100% / (16 / 9));
    background-position: top right;
    background-size: 20% 15% !important;
    margin-top: 0.5%;
    padding-right: 63px;
    margin-left: 10%;
    margin: auto !important;
  }*/
}