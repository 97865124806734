
.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
}

.PDFPage {
    box-shadow: 0 5px 8px rgba(0, 0, 0, .5);
    overflow: auto !important;
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
    overflow: auto !important;
}