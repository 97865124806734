/*!

 =========================================================
 * Material Dashboard React - v1.8.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @font-face {
  font-family: 'Sansational';
  src: local('Sansational'), url(../font/Sansation-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Sansational';
    /* font-weight: 900; */
    src: local('Sansational'), url(../font/Sansation_Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Sansational';
    /* font-weight: 900; */
    src: local('Sansational'), url(../font/Sansation_Italic.ttf) format('opentype');
}
@font-face {
  font-family: 'Sansational';
  /* font-weight: 900; */
  src: local('Sansational'), url(../font/Sansation_Light.ttf) format('opentype');
}


.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*-webkit-touch-callout: none;*/ /* iOS Safari */
  /*-webkit-user-select: none;*/ /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
         /* user-select: none;*/ /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
/* body {
  background-color: #eeeeee !important; 
  background-image: linear-gradient(to right, #9c27b0 , #d65cee);
} */

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

/* h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
} */


blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6{
  font-family: "Sansational", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300;
  line-height: 1.5em;
}

span,
samp,
p {
  font-family: "Sansational", "Helvetica", "Arial", sans-serif !important;
}

a{
  text-decoration: none;
  color:#fff
  /* color:#5b5b5b */
}

a:hover,
a:focus {
  color:#00bcd4;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

/* react-tabs css */
.react-tabs__tab-list 
{
  margin-left: 5%;
  margin-right: 10%;
  padding-left: 5px;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  background-color: #fff;
  font-family: "Sansational", "Helvetica", "Arial", sans-serif;
}
.react-tabs__tab {
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: .3em;
  color: #aaa;
  
}

.react-tabs__tab--selected
{
  border-bottom:3px solid #5b5b5b;
  color: #5b5b5b
}
.react-tabs__tab:hover
{
  border-bottom:3px solid #5b5b5b;
  color: #5b5b5b
}



/* -----------MuiFormLabel -----------*/
.MuiFormLabel-root{
  font-family: "Sansational", "Helvetica", "Arial", sans-serif !important; 
}

/*---- Sidebar-------- */

/*Drawer ListItem */
.MuiListItem-root{
  padding-top: 2px !important;
}

/*Drawer icon*/
.MuiListItemIcon-root {
  /* color: rgba(0, 0, 0, 0.54); */
  color: inherit !important;
  display: inline-flex;
  /* min-width: 34px !important; */
  min-width: 30px !important;
  flex-shrink: 0;
}

/*-------Dialog----------- */
.MuiDialog-paperScrollBody {
  vertical-align: top !important;
}



/*  browser scrollbar*/
/* width */

@media screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Notification Css */
.my-anchor-css-class{
  color: #9c27b0;
  margin-left: 10px;
  font-weight: bold;
}

.scrollNotification::-webkit-scrollbar {
  width: 0px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.line-through{
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through; 
}


